<template>
  <div class="chart-view" id="chart-view">
    <!-- <h1>CHARTTI</h1> -->
    <!-- <div style="font-size: 0.5rem;">NODES: {{this.GET_NODES}}</div>
    <br>
    <div style="font-size: 0.5rem;">LINKS: {{this.GET_LINKS}}</div> -->
    <svg-view-port></svg-view-port>
    <screenshot></screenshot>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import svgViewPort from '@/components/svgViewPort'
import screenshot from '@/components/screenshot'

export default {
  components: {
    svgViewPort,
    screenshot
  },

  head() {
    return {
      title: {
        inner: 'Home'
      },
      meta: [
        {
          name: 'description',
          content: `${this.appTitle} home page`,
          id: 'desc'
        }
      ]
    }
  },

  // JATKA TÄSTÄ!!!!
  // JATKA TÄSTÄ!!!!
  // JATKA TÄSTÄ!!!!
  // JATKA TÄSTÄ!!!!
  /* created () {
    this.handleWindowResize()
    window.addEventListener('resize', this.handleWindowResize)
    this.$once('hook:destroyed', () => {
      window.removeEventListener('resize', this.handleWindowResize)
    })
  },

  methods: {
    handleWindowResize () { // track window size
      // HUOM! pitäisikö tässä käyttää Underscore.js:n _.throttle():a?
      return {
        width: window.innerWidth,
        height: window.innerHeight
      }
    }
  }, */

  computed: {
    ...mapState('app', ['appTitle']),
    ...mapGetters('input', ['GET_NODES', 'GET_LINKS']),
  }
}
</script>

<style lang="scss">
@import '@/theme/variables.scss';

.chart-view {
  //background-color: lightblue;
  //background: rgba(0, 140, 0, 0.25);

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  /* position: relative;
  width: 100vw;
  height: 100vh; */
  //padding: 100px;

  //display: flex;
  //flex-direction: column;
  //justify-content: center;
  //align-items: center;
  /* svg {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;
  } */
}

.home-container {
  background-color: purple;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  button {
    padding: 1rem;
    font-size: 2rem;
  }
}

.page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .logo {
    margin-bottom: 3rem;
  }

  .home-page-title {
    text-align: center;
  }

  .documentation-link {
    display: inline-block;
    font-size: 1.2rem;
    color: #fff;
    background-color: #5d6788;
    padding: 0.8rem 1.6rem;
    border-radius: 4px;
    transition: background-color 0.1s ease;
    box-sizing: border-box;
    text-decoration: none;
    width: fit-content;
    font-weight: 500;
  }
}
</style>

import Vue from 'vue'

export default {
  setNetworkOnline: (state, value) => (state.networkOnLine = value),
  setSWRegistrationForNewContent: (state, value) =>
    (state.SWRegistrationForNewContent = value),
  setShowAddToHomeScreenModalForApple: (state, value) =>
    (state.showAddToHomeScreenModalForApple = value),
  setRefreshingApp: (state, value) => (state.refreshingApp = value),

  UPDATE_WINDOW_DIMENSIONS: (state, updatedData) => {
    Vue.set(state, 'window', updatedData)
  },

  SET_MODAL: (state, modal) => {
    state.activeModal = modal
  }
}

<template>
  <div id="screenshot" class="container">

    <!-- <transition name="screenshot">
      <div class="Flash" v-if="tookScreenShot"></div>
    </transition>
  
  
    <div class="overlay" :class="{ 'highlighting' : mouseIsDown }" :style="{ borderWidth: borderWidth }"></div>
    
    <div class="crosshairs" :class="{ 'hidden' : isDragging }" :style="{ left: crossHairsLeft + 'px', top: crossHairsTop + 'px' }"></div>
    
    <div class="borderedBox" :class="{ 'hidden': !isDragging }" :style="{ left: boxLeft + 'px', top: boxTop + 'px', width: boxEndWidth + 'px', height: boxEndHeight + 'px' }"></div>
    
    <span class="tooltip" :class="{ 'hidden': !isDragging }" :style="{ left: toolTipLeft + 'px', top: toolTipTop + 'px'}">{{boxEndWidth}} x {{boxEndHeight}}px</span> -->

    <div class="screenshot-buttons">
      <div>
        <button
          @click="toggleArrowheads"
          data-html2canvas-ignore
        >arrowheads</button>
        <button
          @click="toggleTransparency"
          data-html2canvas-ignore
        >transparency</button>
        <button
          @click="shuffleHorizontalPositions"
          data-html2canvas-ignore
        >shuffle</button>        
      </div>

      <div>
        <button
          @click="goToSetup"
          data-html2canvas-ignore
        >setup</button>
        <!-- <button
          @click="takeScreenshot"
          data-html2canvas-ignore
        >capture</button> -->
      </div>
    </div>
    
  </div>
</template>

<script>
let crosshairs
let overlay
let tooltip

let TOOLTIP_MARGIN
//let TOOLTIP_MARGIN = +window.getComputedStyle(document.querySelector(".tooltip")).margin.split("px")[0];

//let crosshairs = document.querySelector('.crosshairs')
//let overlay = document.querySelector('.overlay')
//let tooltip = document.querySelector('.tooltip')
//let TOOLTIP_MARGIN = +window.getComputedStyle(document.querySelector('.tooltip')).margin.split('px')[0];
import html2canvas from 'html2canvas'

export default {
  //let crosshairs, overlay, tooltip
  //let TOOLTIP_MARGIN = +window.getComputedStyle(document.querySelector(".tooltip")).margin.split("px")[0];

  name: 'screenshot',

  data() {
    return {
      mouseIsDown: false,
      isDragging: false, 
      tookScreenShot: false, // After the mouse is released
      
      // Used to calculate where to start showing the dragging area
      startX: 0,
      startY: 0,
      endX: 0,
      endY: 0,
      
      borderWidth: '',
      
      // Handling the positioning of the crosshairs
      crossHairsLeft: 0,
      crossHairsTop: 0,
      
      // The box that contains the border and all required numbers.
      boxTop: 0,
      boxLeft: 0,
      boxEndWidth: 0,
      boxEndHeight: 0,
      
      // The tooltip's required positioning numbers.
      toolTipLeft: 0,
      toolTipTop: 0,
      toolTipWidth: 0,
      toolTipHeight: 0,
      
      windowHeight: 0,
      windowWidth: 0,

      croppedImageWidth: 1000,
      croppedImageHeight: 1000,
      imageUrl: ''
    }
  },

  /* mounted() {
    // Get svg element.
    let svg = document.getElementById('svgdownload')

    // Get svg source.
    let serializer = new XMLSerializer()
    let source = serializer.serializeToString(svg)

    // Add name spaces.
    if(!source.match(/^<svg[^>]+xmlns="http\:\/\/www\.w3\.org\/2000\/svg"/)){
        source = source.replace(/^<svg/, '<svg xmlns="http://www.w3.org/2000/svg"')
    }
    if(!source.match(/^<svg[^>]+"http\:\/\/www\.w3\.org\/1999\/xlink"/)){
        source = source.replace(/^<svg/, '<svg xmlns:xlink="http://www.w3.org/1999/xlink"')
    }

    // Add xml declaration
    source = '<?xml version="1.0" standalone="no"?>\r\n' + source

    // Convert svg source to URI data scheme.
    let url = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(source)

    // Set url value to a element's href attribute,
    // and download svg file by right click menu.
    document.getElementById('svgdownloadlink').href = url
  }, */

  methods: {
    goToSetup() {
      this.$router.push({ name: 'setup'})
    },

    toggleArrowheads() {
      this.$store.dispatch('chart/TOGGLE_ARROWHEADS')
    },

    toggleTransparency() {
      this.$store.dispatch('chart/TOGGLE_TRANSPARENCY')
    },

    shuffleHorizontalPositions() {
      this.$store.dispatch('input/STORE_NODES_AND_LINKS', { nodes: this.$store.state.input.nodes, links: this.$store.state.input.links})
    },

    takeScreenshot() {
      let svg = document.getElementById('svg-container-inner-id')
      let svgWidth = svg.offsetWidth
      let svgHeight = svg.offsetHeight
      console.log('svg', svg)
      console.log('svgWidth', svgWidth)
      console.log('svgHeight', svgHeight)

      let options = {
        width: svgWidth,
        height: svgHeight,
        //removeContainer: false
        //backgroundColor: 'red',
      }

      let chartElem = document.body
      let chartWidth = chartElem.offsetWidth
      let chartHeight = chartElem.offsetHeight
      //console.log('chartWidth', chartWidth);
      //console.log('chartHeight', chartHeight);

      html2canvas(svg, options).then(canvas => {
        console.log('canvas', canvas)
        console.log('canvas.style.width', canvas.style.width)
        console.log('canvas.style.height', canvas.style.height)
        console.log('canvas.innerWidth', canvas.innerWidth)
        console.log('canvas.offsetWidth', canvas.offsetWidth)
        
        let croppedCanvas = document.createElement('canvas')
        console.log('croppedCanvas', croppedCanvas)

        croppedCanvas.width = svgWidth
        croppedCanvas.height = svgHeight
        console.log('croppedCanvas.width', croppedCanvas.width)
        console.log('croppedCanvas.height', croppedCanvas.height)

        let croppedCanvasContext = croppedCanvas.getContext('2d')
        console.log('croppedCanvasContext', croppedCanvasContext)
        //croppedCanvasContext.drawImage(canvas, 0, 0)
        //croppedCanvasContext.drawImage(canvas, 0, 0, svgWidth, svgHeight, 0, 0, svgWidth, svgHeight);
        croppedCanvasContext.drawImage(canvas, 0, 0, svgWidth, svgHeight, 0, 0, svgWidth, svgHeight)
        //croppedCanvasContext.drawImage(canvas, 100, 100, (chartWidth - 200), (chartHeight - 200), 100, 100, (chartWidth - 200), (chartHeight - 200));

        
        let imageUrl = croppedCanvas.toDataURL()
        console.log('imageUrl', imageUrl)
        this.$store.dispatch('chart/SET_SCREEN_CAPTURE_URL', imageUrl)

        // TÄMÄN PITI TALLENTAA PNG:NÄ MUTTA TALLENTAA TEKSTIMUODOSSA -> TWIIKKAA!!!
        //let imitsi = croppedCanvas.toDataURL("image/png").replace("image/png", "image/octet-stream");  // here is the most important part because if you dont replace you will get a DOM 18 exception.
        //window.location.href=imitsi;
      })

      /* html2canvas(document.querySelector('body'), options).then(canvas => {
        console.log('canvas', canvas);
        console.log('canvas.style.width', canvas.style.width);
        console.log('canvas.style.height', canvas.style.height);
        console.log('canvas.innerWidth', canvas.innerWidth);
        console.log('canvas.offsetWidth', canvas.offsetWidth);
        
        let croppedCanvas = document.createElement('canvas')
        let croppedCanvasContext = croppedCanvas.getContext('2d')

        croppedCanvas.width  = chartWidth
        croppedCanvas.height = chartHeight

        croppedCanvasContext.drawImage(canvas, 0, 0)
        //croppedCanvasContext.drawImage(canvas, 0, 0, chartWidth, chartHeight, 0, 0, chartWidth, chartHeight);
        //croppedCanvasContext.drawImage(canvas, 100, 100, (chartWidth - 200), (chartHeight - 200), 100, 100, (chartWidth - 200), (chartHeight - 200));

        
        let imageUrl = croppedCanvas.toDataURL();
        console.log('imageUrl', imageUrl);
        this.$store.dispatch('chart/SET_SCREEN_CAPTURE_URL', imageUrl)

        // TÄMÄN PITI TALLENTAA PNG:NÄ MUTTA TALLENTAA TEKSTIMUODOSSA -> TWIIKKAA!!!
        //let imitsi = croppedCanvas.toDataURL("image/png").replace("image/png", "image/octet-stream");  // here is the most important part because if you dont replace you will get a DOM 18 exception.
        //window.location.href=imitsi;
      }) */

      /* html2canvas(document.querySelector('body')).then(canvas => {
        console.log('canvas', canvas);
        
        let croppedCanvas = document.createElement('canvas')
        let croppedCanvasContext = croppedCanvas.getContext('2d')
        console.log('croppedCanvas', croppedCanvas);

        //console.log('this.croppedImageWidth', this.croppedImageWidth);
        //console.log('this.croppedImageHeight', this.croppedImageHeight);
        
        croppedCanvas.width  = window.innerWidth
        croppedCanvas.height = window.innerHeight
        console.log('croppedCanvas.width', croppedCanvas.width);
        console.log('croppedCanvas.height', croppedCanvas.height);
        console.log('croppedCanvasContext', croppedCanvasContext);

        //croppedCanvasContext.drawImage(canvas, this.startX, this.startY, this.croppedImageWidth, this.croppedImageHeight, 0, 0, this.croppedImageWidth, this.croppedImageHeight)
        croppedCanvasContext.drawImage(canvas, 0, 0, window.innerWidth, window.innerHeight)

        let imageUrl = croppedCanvas.toDataURL()
        console.log('imageUrl', imageUrl);

        let imgi = '<img src="' + url + '">'
        window.open(imageUrl).write(img)
      }) */
    },

    /* domNodes() {
      let crosshairs = document.querySelector('.crosshairs')
      let overlay = document.querySelector('.overlay')
      let tooltip = document.querySelector('.tooltip')
      let TOOLTIP_MARGIN = +window.getComputedStyle(document.querySelector('.tooltip')).margin.split('px')[0];

      //console.log('crosshairs', crosshairs);
      //console.log('overlay', overlay);
      //console.log('tooltip', tooltip);
      //console.log('TOOLTIP_MARGIN', TOOLTIP_MARGIN);

      return {
        crosshairs,
        overlay,
        tooltip,
        TOOLTIP_MARGIN
      }
    }, */
    
    /* move(e) {
      console.log('mouse move');
      
      this.crossHairsTop = e.clientY
      this.crossHairsLeft = e.clientX
      
      //let tooltipBoundingRect = this.domNodes().tooltip.getBoundingClientRect()
      let tooltipBoundingRect = tooltip.getBoundingClientRect()
      
      this.toolTipWidth = tooltipBoundingRect.width
      this.toolTipHeight = tooltipBoundingRect.height
      
      // Change how the borderWidth is being calculated based on the x and y values.
      // Calculate the box with the 1px border's positioning and width 
      // Calculate the positioning of the tooltip
      if (this.mouseIsDown) {
        
        let endY = this.endY = e.clientY,
            endX = this.endX = e.clientX,
            startX = this.startX,
            startY = this.startY,
            windowWidth = this.windowWidth,
            windowHeight = this.windowHeight
        
        // Calculating the values differently depending on how the user start's dragging.
        if (endX >= startX && endY >= startY) {
          
          this.isDragging = true
          
          this.borderWidth = startY + 'px ' + (windowWidth - endX) + 'px ' + (windowHeight - endY) + 'px ' + startX + 'px'
          
          this.boxTop = startY
          this.boxLeft = startX
          this.boxEndWidth = endX - startX
          this.boxEndHeight = endY - startY
          
          this.toolTipLeft = endX
          this.toolTipTop = endY
          
          if (endX + this.toolTipWidth >= windowWidth) {
            this.toolTipLeft = windowWidth - this.toolTipWidth - (TOOLTIP_MARGIN * 2)
          }
          
          if (endY + this.toolTipHeight + (TOOLTIP_MARGIN * 2) >= windowHeight) {
            this.toolTipTop = windowHeight - this.toolTipHeight - (TOOLTIP_MARGIN * 2)
          }
          
        } else if (endX <= startX && endY >= startY) {
          
          this.isDragging = true
          
          this.borderWidth = startY + 'px ' + (windowWidth - startX) + 'px ' + (windowHeight - endY) + 'px ' + endX + 'px'
          
          this.boxLeft = endX
          this.boxTop = startY
          this.boxEndWidth = startX - endX
          this.boxEndHeight = endY - startY
          
          this.toolTipLeft = endX - this.toolTipWidth
          this.toolTipTop = endY
          
          if (endX - this.toolTipWidth <= 0) {
            this.toolTipLeft = TOOLTIP_MARGIN
          }
          
          if (endY + this.toolTipHeight + (TOOLTIP_MARGIN * 2) >= windowHeight) {
            this.toolTipTop = windowHeight - this.toolTipHeight - (TOOLTIP_MARGIN * 2)
          }
          
        } else if (endX >= startX && endY <= startY) {
          
          this.isDragging = true
          
          this.boxLeft = startX
          this.boxTop = endY
          this.boxEndWidth = endX - startX
          this.boxEndHeight = startY - endY
          
          this.toolTipLeft = endX
          this.toolTipTop = endY - this.toolTipHeight
          
          this.borderWidth = endY + 'px ' + (windowWidth - endX) + 'px ' + (windowHeight - startY) + 'px ' + startX + 'px'
          
          if (endX + this.toolTipWidth >= windowWidth) {
            this.toolTipLeft = windowWidth - this.toolTipWidth - (TOOLTIP_MARGIN * 2)
          }
          
          if (endY - this.toolTipHeight <= 0) {
            this.toolTipTop = TOOLTIP_MARGIN
          }
          
        } else if (endX <= startX && endY <= startY) {
          
          this.isDragging = true
          
          this.boxLeft = endX
          this.boxTop = endY
          this.boxEndWidth = startX - endX
          this.boxEndHeight = startY - endY
          
          this.borderWidth = endY + 'px ' + (windowWidth - startX) + 'px ' + (windowHeight - startY) + 'px ' + endX + 'px'
          
          this.toolTipLeft = endX - this.toolTipWidth
          this.toolTipTop = endY - this.toolTipHeight
          
          if (endX - this.toolTipWidth <= 0) {
            this.toolTipLeft = TOOLTIP_MARGIN
          }
          
          if (endY - this.toolTipHeight <= 0) {
            this.toolTipTop = TOOLTIP_MARGIN
          }
          
        } else {
          this.isDragging = false
        }
        
      }
      
    }, */
    
    /* mouseDown(e) {
      console.log('mouse down');
      this.borderWidth = this.windowWidth + 'px ' + this.windowHeight + 'px' 
      
      this.startX = e.clientX
      this.startY = e.clientY
      
      //this.toolTipWidth = this.domNodes().tooltip.getBoundingClientRect().width
      this.toolTipWidth = tooltip.getBoundingClientRect().width
      
      this.mouseIsDown = true
      this.tookScreenShot = false
    }, */
    
    /* mouseUp(e) {
      console.log('mouse up');
      this.borderWidth = 0
      
      if (this.isDragging) {
        // Don't take the screenshot unless the mouse moved somehow.
        this.tookScreenShot = true
      }
      
      this.isDragging = false
      this.mouseIsDown = false
      this.takeScreenshot()
    } */
  }
}
</script>

<style lang="scss" scoped>
/* Foundation */
/* *,
*:before,
*:after {
    box-sizing: border-box;
}

html, body {
  padding: 0;
  margin: 0;
  height: 100%;
} */

#screenshot {
  pointer-events: none;
  & * { pointer-events: auto; }
  .screenshot-buttons {
    display: flex;
    //justify-content: flex-end;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 1rem;
    height: 1rem;
    button {
      display: block;
      height: 1.4rem;
      margin-left: 0.5rem;
    }
    div {
      display: flex;
      align-items: center;

    }
  }
}

.overlay,
.crosshairs,
.tooltip,
.borderedBox {
  user-select: none; // Prevents text selection in/from div
}

.overlay { // Dark semi transparent background color visible when dragging area for screenshot
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.overlay.highlighting {
  background: none;
  border-color: rgba(0, 0, 0, 0.5);
  border-style: solid;
}

.crosshairs {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1000000;
}

.crosshairs.hidden { display: none; }

.crosshairs::before,
.crosshairs::after {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  border: none !important;
  border-image:  !important;
}

.crosshairs::before {
  left: -100%;
  top: -100%;
  border-right: 1px solid rgba(255, 255, 255, 0.3) !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3) !important;
}

.crosshairs::after {
  left: 0px;
  top: 0px;
  border-top: 1px solid rgba(255, 255, 255, 0.3) !important;
  border-left: 1px solid rgba(255, 255, 255, 0.3) !important;
}

.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  //background: rgba(0, 140, 0, 0.5);

  //clear: both;
  //overflow: hidden;
  //width: 100%;
  //height: 100%;
  //background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/260969/kendrick-lamar.png");
  //background-repeat: no-repeat;
  //background-size: cover;
}

.borderedBox {
  border: 1px solid #fff;
  position: absolute;
}

.borderedBox.hidden {
  display: none;
}

.tooltip {
  display: inline-block;
  position: absolute;
  background-color: grey;
  color: #fff;
  border-radius: 4px;
  font-size: 12px;
  //font-family: monospace;
  padding: 6px;
  margin: 6px;
  white-space: nowrap;
}

.tooltip.hidden { display: none; }

.Flash {
  z-index: 1000000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  background-color: #fff;
  animation-delay: 0.2s;
  animation-name: fade-out;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.screenshot-enter-active, .screenshot-leave-active { transition: opacity .2s; }
.screenshot-enter, .screenshot-leave-to { opacity: 0; }

@keyframes fade-out {
  from { opacity: 1; }
  to { opacity: 0; }
}
</style>

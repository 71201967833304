<template>
  <div id="app">
    <app-handler></app-handler>
    <!-- <nav-bar></nav-bar> -->
    <router-view />

    <captured-image v-if="$store.state.chart.screenCaptureUrl"></captured-image>

    <!-- <new-content-available-toastr
      v-if="newContentAvailable"
      class="new-content-available-toastr"
      :refreshing-app="refreshingApp"
      @refresh="serviceWorkerSkipWaiting"
    ></new-content-available-toastr> -->
    
    <!-- <apple-add-to-home-screen-modal
      v-if="showAddToHomeScreenModalForApple"
      class="apple-add-to-home-screen-modal"
      @close="closeAddToHomeScreenModalForApple(false)"
    >
    </apple-add-to-home-screen-modal> -->
  </div>
</template>
<script>
import appHandler from '@/components/appHandler'
import capturedImage from '@/components/capturedImage'
import NavBar from '@/components/NavBar'
import NewContentAvailableToastr from '@/components/NewContentAvailableToastr'
import AppleAddToHomeScreenModal from '@/components/AppleAddToHomeScreenModal'
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  components: { appHandler, capturedImage, NavBar, NewContentAvailableToastr, AppleAddToHomeScreenModal },
  computed: {
    ...mapGetters('app', ['newContentAvailable']),
    ...mapState('app', ['showAddToHomeScreenModalForApple', 'refreshingApp'])
  },
  methods: mapActions('app', [
    'closeAddToHomeScreenModalForApple',
    'serviceWorkerSkipWaiting'
  ])
}
</script>

<style lang="scss">
a {
  font-weight: 500;
  text-decoration: none;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

html, body {
  padding: 0;
  margin: 0;
  height: 100%;
}

div {
  padding: 0;
  margin: 0;
  //height: 100%;
}

button {
  cursor: pointer;
  border: 1px solid lightgrey;
  border-radius: 3px;
  background: transparent;
}

input {
  border: 1px solid lightgrey;
  border-radius: 3px;
  background: transparent;
}

#app {
  position: relative;
  //padding: 100px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  color: #2c3e50;
}

.tiny-button {
  margin-top: 0.2rem;
  padding: 0.3rem !important;
  font-size: 0.7rem !important;
}
</style>

import state from './input.state'
import mutations from './input.mutations'
import actions from './input.actions'
import getters from './input.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

import Vue from 'vue'
import Router from 'vue-router'
import Head from 'vue-head'

import Home from '@/views/Home'
import Setup from '@/views/Setup'
import Chart from '@/views/Chart'

import CheckLogin from '@/views/CheckLogin'
import { isNil } from 'lodash'
import store from '@/store'

Vue.use(Router)

/* If you don't know about VueHead, please refer to https://github.com/ktquez/vue-head */
Vue.use(Head, {
  complement: process.env.VUE_APP_TITLE
})

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/setup', // YHDISTÄ SETUP JA HOME MYÖHEMMIN!!!
      name: 'setup',
      component: Setup,
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/chart',
      name: 'chart',
      component: Chart,
      //component: svgBox,
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/check-login',
      name: 'check-login',
      component: CheckLogin,
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/login',
      name: 'login',
      component: () =>
        import(/* webpackChunkName: "client-chunk-login" */ '@/views/Login.vue'),
      meta: {
        authNotRequired: true
      }
    },
    { path: '*', redirect: '/' }
  ]
})

/**
 * Handle user redirections
 */
/* router.beforeEach((to, from, next) => {
  if (
    !(to.meta && to.meta.authNotRequired) &&
    isNil(store.state.authentication.user)
  ) {
    const path =
      store.state.authentication.user === null ? '/login' : '/check-login'
    return next(`${path}?redirectUrl=${to.path}`)
  }
  next()
}) */

export default router

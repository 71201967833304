<template>
  <div class="home-container">

    <div>
      <h1>SETUP</h1>

      <!-- FOR DEBUGGING ONLY -->
      <!-- <div style="display: inline-block; margin: 5px; padding: 5px">
        <span v-for="node in nodes" style="padding: 5px">{{ node.id }} ({{ node.trophicLevel }})</span>
      </div> -->

      <br />

      <!-- node data and links -->
      <label for="linkFile">Load Feeding links</label>
      <input id="linkFile" ref="inputLink" type="file" name="linkFile" @change="handleLinkFileSelect" />
      <br />
      <!-- node labels -->
      <label for="idFile">Load Node labels</label>
      <input id="idFile" ref="inputLabel" type="file" name="idFile" @change="handleIdFileSelect" />
      <br />
      <!-- node sizes -->
      <label for="nodeSizeFile">Load Node Sizes</label>
      <input id="nodeSizeFile" ref="inputSize" type="file" name="nodeSizeFile" @change="handleNodeSizeFileSelect" />
      <br />
      <!-- node colors -->
      <label for="nodeColorFile">Load Node Colors</label>
      <input id="nodeColorFile" ref="inputColor" type="file" name="nodeColorFile" @change="handleNodeColorFileSelect" />
      <br />
      <!-- node trophic levels -->
      <label for="nodeColorFile">Load Node Trophic Levels</label>
      <input id="nodeTLFile" ref="inputTL" type="file" name="nodeTLFile" @change="handleNodeTLFileSelect" />

      <br />

      <br />
      <span class="instructions" @click="setModal('tips')">INSTRUCTIONS</span>
      <br /><br />

      <button @click="goToChart">CHART</button>
      <br /><br />

      <!-- TOMMI: KOMMENTOI TÄMÄ ESIIN NIIN SAAT ERROR_MODAALIN AUKI! -->
      <!-- TOMMI: KOMMENTOI TÄMÄ ESIIN NIIN SAAT ERROR_MODAALIN AUKI! -->
      <!-- TOMMI: KOMMENTOI TÄMÄ ESIIN NIIN SAAT ERROR_MODAALIN AUKI! -->
      <!-- TOMMI: KOMMENTOI TÄMÄ ESIIN NIIN SAAT ERROR_MODAALIN AUKI! -->
      <!-- TOMMI: KOMMENTOI TÄMÄ ESIIN NIIN SAAT ERROR_MODAALIN AUKI! -->
      <!-- TOMMI: KOMMENTOI TÄMÄ ESIIN NIIN SAAT ERROR_MODAALIN AUKI! -->
      <!-- <button class="tiny-button" @click="setModal('error')">ModalError</button> -->
    </div>

    <div class="home-container-extras">
      <div class="chrome">
        <img src="@/assets/chrome-logo-monochrome.png" alt="" />
        <span>Optimized for chrome</span>
      </div>
    </div>

    <modal-tips v-if="activeModal === 'tips'"></modal-tips>
    <modal-error v-if="activeModal === 'error'"></modal-error>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { create, all } from 'mathjs'

const ModalError = () => import('@/components/ModalError')
const modalTips = () => import('@/components/modalTips')

export default {
  components: {
    ModalError,
    modalTips
  },

  head: function() {
    return {
      title: {
        inner: 'Home'
      },
      meta: [
        {
          name: 'description',
          content: `${this.appTitle} home page`,
          id: 'desc'
        }
      ]
    }
  },

  data() {
    return {
      nodes: [],
      links: []
    }
  },

  computed: {
    ...mapState('app', ['appTitle', 'activeModal'])
  },

  methods: {
    setModal(modal) {
      this.$store.dispatch('app/SET_MODAL', modal)
    },

    handleLinkFileSelect(evt) {
      this.nodes = []
      this.links = []

      const file = evt.target.files[0]
      const self = this
      const reader = new FileReader()
      reader.onload = function(e) {
        //console.log('e.target', e.target);
        //console.log(e.target.result, 'e.target.result');
        const linkArray = e.target.result
          .split('\n')
          .filter(el => { return el.replace(/(\r\n|\n|\r)/gm,"") })
          .map(row => row.split(',').map(el => el[0]))
        /* const linkArray = e.target.result.split('\n').filter(el => { return el != '' })
          .map(row => row.split(',').map(el => el[0])) */
          //console.log(linkArray)
        /* const linkArray = e.target.result
          .split('\n')
          .map(row => row.split(',').map(el => el[0])) */
        if (self.nodes.length === 0) {
          // labels not loaded yet and thus nodes array is empty. let's initialize it
          for (let i = 0; i < linkArray.length; i++) {
            self.nodes.push({
              id: 'NODE_' + i,
              size: 1,
              color: '#85baff',
              trophicLevel: null,
              x: null,
              y: null
            })
          }
        }

        // create unweighted link matrix
        const uLinkArray = []

        linkArray.forEach((row, ind) => {
          uLinkArray[ind] = []
          row.forEach((el, jnd) => {
            if (el !== '0') {
              // '0' means there is no link. Otherwise link exists and the line width is determined by el.
              // let's create link from node indices
              self.links.push({
                inds: [ind, jnd],
                pos: {
                  x1: null,
                  y1: null,
                  x2: null,
                  y2: null                  
                },
                linewidth: el
              })

              uLinkArray[ind][jnd] = '1'
            } else {
              uLinkArray[ind][jnd] = '0'
            }
            /* if (el === '1') {
              // '1' means there is a link. let's create link from node indices
              self.links.push({
                inds: [ind, jnd],
                pos: {
                  x1: null,
                  y1: null,
                  x2: null,
                  y2: null
                }
              })
            } */
          })
        })

        /* linkArray.forEach((row, ind) => {
          row.forEach((el, jnd) => {
            if (el !== '0') {
              // '0' means there is no link. Otherwise link exists and the line width is determined by el.
              // let's create link from node indices
              self.links.push({
                inds: [ind, jnd],
                pos: {
                  x1: null,
                  y1: null,
                  x2: null,
                  y2: null                  
                },
                linewidth: el
              })

              uLinkArray[ind][jnd] = '1'
            } else {
              uLinkArray[ind][jnd] = '0'
            }
            if (el === '1') {
              // '1' means there is a link. let's create link from node indices
              self.links.push({
                inds: [ind, jnd],
                pos: {
                  x1: null,
                  y1: null,
                  x2: null,
                  y2: null
                }
              })
            }
          })
        }) */
        //console.log('linkArray', linkArray);        

        // simple integer valued trophic levels based on shortest distance to autotrophs
        //const trophicLevelArray = self.calculateTrophicLevels(uLinkArray, 'shortestPath')

        // fractional valued trophic levels based on the average TL of prey
        const trophicLevelArray = self.calculateTrophicLevels(uLinkArray, 'preyAveraged')

        trophicLevelArray.forEach((tl, ind) => {
          self.nodes[ind].trophicLevel = tl
        })
      }
      reader.readAsText(file)
    },

    handleIdFileSelect(evt) {
      const file = evt.target.files[0]
      const self = this
      const reader = new FileReader()
      reader.onload = function(e) {
        const IdArray = e.target.result
          .split('\n')
          .filter(el => { return el.replace(/(\r\n|\n|\r)/gm,"") })
          //.filter(el => { return el != '' })
        if (self.nodes.length === 0) {
          // links not loaded yet. initialize nodes array with node labels
          IdArray.forEach(id => {
            self.nodes.push({
              id: id,
              size: 1,
              color: '#CED8F6',
              trophicLevel: null,
              x: null,
              y: null
            })
          })
        } else {
          // links already loaded. modify node labels
          IdArray.forEach((id, ind) => {
            self.nodes[ind].id = id
          })
        }
      }
      reader.readAsText(file)
    },

    handleNodeSizeFileSelect(evt) {
      const file = evt.target.files[0]
      const self = this
      const reader = new FileReader()
      reader.onload = function(e) {
        const nodeSizeArray = e.target.result
          .split('\n')
          .filter(el => { return el.replace(/(\r\n|\n|\r)/gm,"") })
          //.filter(el => { return el != '' })
        if (self.nodes.length === 0) {
          // links not loaded yet. initialize nodes array with node labels
          nodeSizeArray.forEach(size => {
            self.nodes.push({
              id: null,
              size: size.trim(),
              color: '#CED8F6',
              trophicLevel: null,
              x: null,
              y: null
            })
          })
        } else {
          // links already loaded. modify node labels
          nodeSizeArray.forEach((size, ind) => {
            self.nodes[ind].size = size.trim()
          })
        }
      }
      reader.readAsText(file)
    },

    handleNodeColorFileSelect(evt) {
      const file = evt.target.files[0]
      const self = this
      const reader = new FileReader()
      reader.onload = function(e) {
        const nodeColorArray = e.target.result
          .split('\n')
          .filter(el => { return el.replace(/(\r\n|\n|\r)/gm,"") })          
          //.filter(el => { return el != '' })
        if (self.nodes.length === 0) {
          // links not loaded yet. initialize nodes array with node colors
          nodeColorArray.forEach(color => {
            self.nodes.push({
              id: null,
              size: 1,
              color: color.trim(),
              trophicLevel: null,
              x: null,
              y: null
            })
          })
        } else {
          // links already loaded. modify node labels
          nodeColorArray.forEach((color, ind) => {
            self.nodes[ind].color = color.trim()
          })
        }
      }
      reader.readAsText(file)
    },

    handleNodeTLFileSelect(evt) {
      const file = evt.target.files[0]
      const self = this
      const reader = new FileReader()
      reader.onload = function(e) {
        const nodeTLArray = e.target.result
          .split('\n')
          .filter(el => { return el.replace(/(\r\n|\n|\r)/gm,"") })          
        if (self.nodes.length === 0) {
          // links not loaded yet. initialize nodes array with node trophicLevels
          nodeTLArray.forEach(TL => {
            self.nodes.push({
              id: null,
              size: 1,
              color: '#CED8F6',
              trophicLevel: TL.trim(),
              x: null,
              y: null
            })
          })
        } else {
          // links already loaded. modify node trophicLevels
          nodeTLArray.forEach((TL, ind) => {
            self.nodes[ind].trophicLevel = TL.trim()
          })
        }
      }
      reader.readAsText(file)
    },

    calculateTrophicLevels(linkArray, algo = 'shortestPath') {
      if (algo === 'shortestPath') {
        const TL = []
        const TLInds = []

        const n = linkArray.length
        let nodeInds = Array.apply(null, Array(n)).map((x, i) => {
          return i
        })

        // find autotrophs (TL = 1)
        let iTL = 0
        TLInds[iTL] = []
        linkArray.forEach((row, ind) => {
          if (row.indexOf('1') === -1) {
            TL[ind] = iTL + 1
            TLInds[iTL].push(ind)
          }
        })

        // remove assigned node indices
        let nodeIndsNew = []
        nodeInds.forEach(el => {
          if (TLInds[iTL].indexOf(el) === -1) {
            nodeIndsNew.push(el)
          }
        })
        nodeInds = nodeIndsNew

        let nLeft = nodeInds.length
        let nLeftNew
        let isStuck = false
        while (nLeft > 0 && !isStuck) {
          // let's loop through higher trophic levels as long as there are unassigned nodes.
          // if for some reason no new nodes were assigned trophic level values, stop to avoid
          // infinite loop (isStuck)
          iTL++
          TLInds[iTL] = []
          linkArray.forEach((row, ind) => {
            if (nodeInds.indexOf(ind) > -1) {
              row.forEach((el, jnd) => {
                if (el === '1') {
                  if (TL[jnd] === iTL) {
                    TL[ind] = iTL + 1
                    TLInds[iTL].push(ind)
                  }
                }
              })
            }
          })

          // remove assigned node indices
          nodeIndsNew = []
          nodeInds.forEach(el => {
            if (TLInds[iTL].indexOf(el) === -1) {
              nodeIndsNew.push(el)
            }
          })
          nodeInds = nodeIndsNew
          nLeftNew = nodeInds.length
          if (nLeft === nLeftNew) {
            isStuck = true
          } else {
            nLeft = nLeftNew
          }
        }

        return TL
      } else if (algo === 'preyAveraged') {
        const math = create(all, {})

        let TL = []

        const n = linkArray.length
        const linkMatrix = math.map(linkArray, Number)
        const eye = math.identity(n)

        const omega = math.apply(linkMatrix, 1, x => {
          const rowSum = math.sum(x)
          if (rowSum > 0) {
            return 1 / rowSum
          } else {
            return 0
          }
        })

        const Q = math.multiply(math.diag(omega), linkMatrix)
        const ImQ = math.subtract(eye, Q)
        const invImQ = math.inv(ImQ)

        TL = math.apply(invImQ, 1, math.sum)

        return TL
      }
    },

    goToChart() {
      //let fileLinks = this.$refs.inputLink.value
      //let fileLabel = this.$refs.inputLabel.value
      //let fileSize = this.$refs.inputSize.value
      //let fileColor = this.$refs.inputColor.value

      if (this.links.length > 0) {
        this.$store.dispatch('input/STORE_NODES_AND_LINKS', { nodes: this.nodes, links: this.links})
        this.nodes = []
        this.links = []
        this.$router.push('/chart')
      } else {
        this.$store.dispatch('app/SET_MODAL', 'error')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.home-container {
  background-color: white;
  /* position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; */
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  //flex-direction: column;
  button {
    padding: 1rem;
    font-size: 2rem;
  }
  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .instructions {
    font-size: 0.9rem;
    font-weight: bold;
    color: red;
    cursor: pointer;
  }
}

.page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .logo {
    margin-bottom: 3rem;
  }

  .home-page-title {
    text-align: center;
  }

  .documentation-link {
    display: inline-block;
    font-size: 1.2rem;
    color: #fff;
    background-color: #5d6788;
    padding: 0.8rem 1.6rem;
    border-radius: 4px;
    transition: background-color 0.1s ease;
    box-sizing: border-box;
    text-decoration: none;
    width: fit-content;
    font-weight: 500;
  }
}

.home-container-extras {
  .chrome {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    //flex-direction: column;
    align-items: center;
    img {
      opacity: 0.4;
      width: 25px;
      height: auto;
      margin-right: 6px;
    }
    span {
      opacity: 0.65;
      font-family: sans-serif;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.7rem;
    }
  }
}
</style>

export default {
  GET_NODES_AND_LINKS: state => {
    return {
      nodes: state.nodes,
      links: state.links
    }
  },

  GET_NODES: state => {
    let nodeData = state.nodes
    return nodeData
  },

  GET_LINKS: state => {
    return state.links
  }
}

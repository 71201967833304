export default {
  // actionName: ({ commit, dispatch, state, rootState, getters }, payload) => {}

  STORE_NODES_AND_LINKS: ({ commit, rootState }, data) => {
    let nodes = JSON.parse(JSON.stringify(data.nodes))
    let links = JSON.parse(JSON.stringify(data.links))

    // Calculate viewBox dimensions and the biggest trophic level from database.
    let viewBoxDimension = rootState.chart.viewBoxDimension //!!POISTUU / EI KÄYTÖSSÄ VISSIIN!!!!!//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    
    let viewBoxDimensionX = rootState.app.window.width - (2 * rootState.chart.viewBoxPadding)
    let viewBoxDimensionY = rootState.app.window.height - (2 * rootState.chart.viewBoxPadding)
    let maxTrophicLevel = Math.max.apply(Math, nodes.map(function(node) { return node.trophicLevel; }))
    //console.log('maxTrophicLevel', maxTrophicLevel);
    
    let trophicLevelRange = viewBoxDimensionY / maxTrophicLevel

    // To generate random integer between min and max,
    // where min and max are included.
    // EI KÄYTÖSSÄ TÄLLÄ HETKELLÄ
    /* function randomIntFromInterval(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min);
    } */

    // Randomize array element order in-place using Durstenfeld shuffle algorithm.
    function shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
          let j = Math.floor(Math.random() * (i + 1))
          let temp = array[i]          
          array[i] = array[j]
          array[j] = temp
      }
      return array
    }

    // Create an object of arrays, where there is an array for each trophic level
    // and it's going to be filled eventually with nodes' x coordinates.
    // Every array's (= value) key is corresponding number of that specific trophic level.
    let xCoordinates = {} // TÄÄLTÄ KOORDSIT!!!!!
    for (let i = 0; i < maxTrophicLevel; i++) {
      xCoordinates[i + 1] = []
    }

    // Fill arrays with node ids to later calculate how many nodes on each trophic level
    nodes.forEach(node => {
      let key = node.trophicLevel.toString()[0]
      xCoordinates[key].push(node.id)
    })

    // Set empty space on/inside of svg's edges (= 'padding')
    let offset = 100

    // Turn arrays of ids into arrays of x coordinates
    for (let array in xCoordinates) {
      // With each array count how many parts full width of chart is divided into
      let jakoLuku = xCoordinates[array].length

      // Count all x positions and replace ids in arrays with actual coordinates
      for (let i = 0; i < jakoLuku; i++) {
        let vaiheenLeveys = (viewBoxDimensionX - 2 * offset) / jakoLuku
        let vaiheenMax = vaiheenLeveys * (i + 1) + offset
        let koordinaattiX = vaiheenMax - (vaiheenLeveys / 2)

        xCoordinates[array][i] = koordinaattiX
      }
      // Shuffle arrays for better visual result with actual chart
      shuffleArray(xCoordinates[array])
    }

    // To give node y coordinate based on it's trophic level
    function calcY(lvl) {
      let levelHeight = (viewBoxDimensionY - 2 * offset) / (maxTrophicLevel - 1)
      let y = levelHeight * (lvl - 1) + offset

      return viewBoxDimensionY - y
    }

    // Give x and y coordinates for nodes
    let nodesWithCoordinates = []
    nodes.forEach((node, index) => {
      let key = node.trophicLevel.toString()[0]

      node.y = calcY(node.trophicLevel)
      node.yRel = calcY(node.trophicLevel) / viewBoxDimensionY
      node.x = xCoordinates[key].shift()
      node.xRel = node.x / viewBoxDimensionX

      nodesWithCoordinates.push(node)
    })    

    // Define coordinates for links based on node coordinates
    let linksWithCoordinates = []

    //console.log('links', links);
    
    links.forEach(link => {
      link.pos.x1 = nodesWithCoordinates[link.inds[0]].x
      link.pos.y1 = nodesWithCoordinates[link.inds[0]].y
      link.pos.x2 = nodesWithCoordinates[link.inds[1]].x
      link.pos.y2 = nodesWithCoordinates[link.inds[1]].y

      linksWithCoordinates.push(link)
    })

    //console.log('linksWithCoordinates', linksWithCoordinates);

    let updatedData = {
      nodes: nodesWithCoordinates,
      links: linksWithCoordinates
    }
    
    commit('STORE_NODES_AND_LINKS', updatedData)
    commit('chart/STORE_TROPHIC_LEVEL', trophicLevelRange, { root: true })
    commit('chart/STORE_TROPHIC_LEVEL_AMOUNT', maxTrophicLevel, { root: true })
  },
}

<template>
  <div class="home-container">
    <h1 style="text-align: center; margin-bottom: 4rem;">FOOD WEB<br />VISUALIZATION<br />TOOL</h1>
    <router-link to="/setup" tag="button">START</router-link>
    <!-- <br>
    <router-link to="/load" tag="button">load</router-link> -->
    <!-- <button>start</button>
    <br>
    <button>load</button> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  head: function() {
    return {
      title: {
        inner: 'Home'
      },
      meta: [
        {
          name: 'description',
          content: `${this.appTitle} home page`,
          id: 'desc'
        }
      ]
    }
  },
  computed: mapState('app', ['appTitle'])
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.home-container {
  background-color: white;
  /* position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; */
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  button {
    padding: 1rem;
    font-size: 2rem;
  }
}

.page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .logo {
    margin-bottom: 3rem;
  }

  .home-page-title {
    text-align: center;
  }

  .documentation-link {
    display: inline-block;
    font-size: 1.2rem;
    color: #fff;
    background-color: #5d6788;
    padding: 0.8rem 1.6rem;
    border-radius: 4px;
    transition: background-color 0.1s ease;
    box-sizing: border-box;
    text-decoration: none;
    width: fit-content;
    font-weight: 500;
  }
}
</style>

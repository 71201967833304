export default {
  GET_TROPHIC_LEVEL_INDICATORS: state => {
    let dimension = state.viewBoxDimension
    let range = state.trophicLevelRange
    let amount = state.trophicLevelAmount
    let levelIndicators = []

    for (let i = 0; i <= amount; i++) {
      levelIndicators.push({
        x1: 0,
        y1: range * i,
        x2: dimension,
        y2: range * i
      })
    }

    return levelIndicators
  },

  /* GET_VIEWBOX_DIMENSIONS: (state, getters, rootState) => {
    return {
      state.
      width: ,
      height: 
    }
  } */
}

export default {
  viewBoxDimension: 1000, // tämä poistuu / korvataan dynaamisella
  viewBoxPadding: 0, // original: 24px / 1.5rem
  //viewBoxDimensionX: window.innerWidth,
  //viewBoxDimensionY: window.innerHeight,
  viewBoxDimensionX: document.body.offsetWidth,
  viewBoxDimensionY: document.body.offsetHeight,
  trophicLevelAmount: 1, // 1 is default / minimum
  trophicLevelRange: null, // 1000 is default and is divided based on nodes' biggest level
  screenCaptureUrl: '', // ONKO HYVÄ TÄSSÄ MODULISSA / KONTEKSTISSA?
  nodeBaseSize: 20,
  showArrowHeadsOnLinks: false,
  showNodesAsTransparent: true
}

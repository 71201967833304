<template>
  <div id="app-handler"></div>
</template>

<script>
export default {
  created () {
    this.handleWindowResize()
    window.addEventListener('resize', this.handleWindowResize)
  },

  destroyed () {
    window.removeEventListener('resize', this.handleWindowResize)
  },

  methods: {
    handleWindowResize () {
      // For window.innerWidth/innerHeight tracking and storing into state
      let width = window.innerWidth
      let height = window.innerHeight

      this.$store.dispatch('app/UPDATE_WINDOW_DIMENSIONS', {
        width: width,
        height: height
      })
    }
  }
}
</script>

<style>

</style>

<template>
  <div
    id="svg-container"
    class="svg-container"
    :style="{ padding: $store.state.chart.viewBoxPadding + 'px' }"
  >
    <div id="svg-container-inner-id" class="svg-container-inner">
      <!-- THIS SVG IS FOR LINKS -->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        id="svgdownload"
        :view-box.camel="viewbox"
        class="svg-for-links"
      >
        <defs>
          <!-- arrowhead marker definition -->
          <marker
            id="arrow"
            viewBox="0 0 10 10"
            refX="5"
            refY="5"
            markerWidth="9"
            markerHeight="9"
            markerUnits="userSpaceOnUse"
            orient="auto-start-reverse"
            fill="rgba(0, 0, 0, 0.6)"
          >
            <!-- markerUnits="strokeWidth" -->
            <!-- markerUnits="userSpaceOnUse" -->
            <path d="M 0 0 L 10 5 L 0 10 z" />
          </marker>
          <!-- simple dot marker definition -->
          <marker
            id="dot"
            viewBox="0 0 10 10"
            refX="5"
            refY="5"
            markerWidth="8"
            markerHeight="8"
            markerUnits="userSpaceOnUse"
          >
            <circle cx="5" cy="5" r="5" fill="black" />
          </marker>
        </defs>

        <g>
          <!-- this group contains all links -->
          <g v-for="(link, i) in dynamicLinks" :key="'link' + i">
            <polyline
              :points="`${link.pos.x1},${link.pos.y1} ${link.pos.x2},${link.pos.y2}`"
              class="link"
              vector-effect="non-scaling-stroke"
              stroke="rgba(0, 0, 0, 0.15)"
              :stroke-width="link.linewidth * 2"
            ></polyline>
            <!-- marker-end="url(#arrow)" -->
          </g>
        </g>

        <g>
          <!-- this group contains all nodes -->
          <g v-for="(node, i) in nodes" :key="'node' + i">
            <circle
              class="node"
              :r="node.size * $store.state.chart.nodeBaseSize || 30"
              :cx="node.xRel * 100 + '%'"
              :cy="node.yRel * 100 + '%'"
              :fill="`rgba(${hexToRgb(node.color).r}, ${hexToRgb(node.color).g}, ${hexToRgb(node.color).b}, ${nodeTransparency.fill})`"
              :stroke="`rgba(${hexToRgb(node.color).r}, ${hexToRgb(node.color).g}, ${hexToRgb(node.color).b}, ${nodeTransparency.stroke})`"
              stroke-width="3"
            ></circle>
            <text
              class="node-text"
              :x="node.xRel * 100 + '%'"
              :y="node.yRel * 100 + '%'"
              text-anchor="middle"
              alignment-baseline="middle"
            >
              {{ node.id }}
            </text>
          </g>
        </g>

        <g>
          <!-- this group contains all links -->
          <g v-for="(link, i) in dynamicLinks" :key="'link' + i">
            <polyline
              :points="`${link.pos.x1},${link.pos.y1} ${link.pos.x2},${link.pos.y2}`"
              class="link"
              vector-effect="non-scaling-stroke"
              stroke="rgba(0, 0, 0, 0)"
              :stroke-width="link.linewidth * 2"
              :marker-end="toggledArrowheads"
            ></polyline>
            <!-- marker-end="url(#arrow)" -->
          </g>
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
// TODO:
// -tee nodesta oma komponenttinsa
// -tee linkistä oma komponenttinsa

// SVG LINKS:
// building a dynamic tree diagram: https://medium.com/@krutie/building-a-dynamic-tree-diagram-with-svg-and-vue-js-a5df28e300cd
// path: https://developer.mozilla.org/en-US/docs/Web/SVG/Tutorial/Paths
// fills ja strokes https://developer.mozilla.org/en-US/docs/Web/SVG/Tutorial/Fills_and_Strokes
// dashed line: https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/stroke-dasharray
// visual display of line-ends: https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/stroke-linecap
// line end markers (e.g. arrowheads): https://developer.mozilla.org/en-US/docs/Web/SVG/Element/marker
import { mapState, mapGetters } from 'vuex'
//import LoginVue from '../views/Login.vue'

export default {
  computed: {
    ...mapState('app', ['window']),
    ...mapState('chart', ['viewBoxDimension', 'viewBoxDimensionX', 'viewBoxDimensionY', 'trophicLevelRange', 'viewBoxPadding']),
    ...mapState('input', ['nodes', 'links']),
    ...mapGetters('input', ['GET_LINKS']), //'GET_DYNAMIC_LINKS'

    /* dynLinks() {
      let dimensionX = this.window.width - (2 * this.viewBoxPadding)
      let dimensionY = this.window.height - (2 * this.viewBoxPadding)
      let nodes = this.nodes

      let newArrayOfLinks = []

      this.links.forEach(link => {
        let updatedLink = {
          inds: link.inds,
          linewidth: link.linewidth,
          pos: {
            //x1: link.pos.x1Rel * dimensionX,
            //y1: link.pos.y1Rel * dimensionY,
            //x2: link.pos.x2Rel * dimensionX,
            //y2: link.pos.y2Rel * dimensionY

            x1: nodes[link.inds[0]].xRel * dimensionX,
            y1: nodes[link.inds[0]].yRel * dimensionY,
            x2: nodes[link.inds[1]].xRel * dimensionX,
            y2: nodes[link.inds[1]].yRel * dimensionY
          }
        }
        newArrayOfLinks.push(updatedLink)
      })

      return newArrayOfLinks
    }, */

    toggledArrowheads() {
      const arrowheads = this.$store.state.chart.showArrowHeadsOnLinks
      if (arrowheads) return 'url(#arrow)'
      else return null
    },

    nodeTransparency() {
      const transparent = this.$store.state.chart.showNodesAsTransparent
      if (transparent) {
        return {
          fill: '0.5',
          stroke: '0.85'
        }
      } else {
        return {
          fill: '0.95',
          stroke: '1'
        }
      }
    },

    dynamicLinks() {
      const dimensionX = this.window.width - 2 * this.viewBoxPadding
      const dimensionY = this.window.height - 2 * this.viewBoxPadding
      //let nodes = this.nodes

      let newArrayOfLinks = []

      this.links.forEach(link => {
        const nodes = this.nodes

        const dx = (nodes[link.inds[1]].xRel * dimensionX) - (nodes[link.inds[0]].xRel * dimensionX)
        const dy = (nodes[link.inds[1]].yRel * dimensionY) - (nodes[link.inds[0]].yRel * dimensionY)
        const len = Math.sqrt(dx * dx + dy * dy)

        const ux = dx / len
        const uy = dy / len

        const nodeBaseSize = this.$store.state.chart.nodeBaseSize

        const updatedLink = {
          inds: link.inds,
          linewidth: link.linewidth,
          pos: {
            x1: (nodes[link.inds[0]].xRel * dimensionX) + ux*nodes[link.inds[0]].size*nodeBaseSize,
            y1: (nodes[link.inds[0]].yRel * dimensionY) + uy*nodes[link.inds[0]].size*nodeBaseSize,
            x2: (nodes[link.inds[1]].xRel * dimensionX) - ux*nodes[link.inds[1]].size*nodeBaseSize,
            y2: (nodes[link.inds[1]].yRel * dimensionY) - uy*nodes[link.inds[1]].size*nodeBaseSize
          }
        }
        newArrayOfLinks.push(updatedLink)
      })

      return newArrayOfLinks
    },

    viewbox() {
      const dimX = this.$store.state.app.window.width - (2 * this.$store.state.chart.viewBoxPadding)
      const dimY = this.$store.state.app.window.height - (2 * this.$store.state.chart.viewBoxPadding)
      return `0 0 ${dimX} ${dimY}`
      //return '0 0 1000 1000'
    },

    trophicLevels() {
      return this.$store.getters['chart/GET_TROPHIC_LEVEL_INDICATORS']
    }
  },

  methods: {
    hexToRgb(hex) {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
      return result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
          }
        : null
    }

    /* lineType(type) {
      switch (type) {
        case 'dashed':
          return '4 2'
      }
    } */
    //lineType('dashed')
    //lineType('dotted')
  }
}
</script>

<style lang="scss" scoped>
//.svg-container {}

.svg-container-inner {
  position: relative;
  width: 100vw;
  height: 100vh;
}

.svg-for-nodes {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.svg-for-links {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

svg {
  overflow: visible;
  //padding: 100px;
  //background: rgba(255, 192, 203, 0.5);
  .node-text {
    font-size: 0.8rem;
    font-weight: bold;
  }
}

.link {
  z-index: 999999;
}
</style>

export default {
  // mutationName: (state, value) => {}
  
  STORE_TROPHIC_LEVEL_AMOUNT: (state, levelAmount) => { // NÄMÄ VOISI MYÖS MYÖHEMMIN YHDISTÄÄ
    state.trophicLevelAmount = levelAmount
  },

  STORE_TROPHIC_LEVEL: (state, levelRange) => { // NÄMÄ VOISI MYÖS MYÖHEMMIN YHDISTÄÄ
    state.trophicLevelRange = levelRange
  },

  SET_SCREEN_CAPTURE_URL: (state, imageUrl) => { // NÄMÄ VOISI MYÖS MYÖHEMMIN YHDISTÄÄ
    state.screenCaptureUrl = imageUrl
  },
  
  TOGGLE_ARROWHEADS: state => {
    state.showArrowHeadsOnLinks = !state.showArrowHeadsOnLinks
  },

  TOGGLE_TRANSPARENCY: state => {
    state.showNodesAsTransparent = !state.showNodesAsTransparent
  }
}

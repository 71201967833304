import firebase from 'firebase/app'
import 'firebase/auth'

// The configuration below is not sensitive data. You can serenely add your config here
const firebaseConfig = {
  apiKey: 'AIzaSyCLBHvZXDsQutlW_Ie1_5ApdmoKfj9lPxg',
  authDomain: 'ruokaverkko.firebaseapp.com',
  databaseURL: 'https://ruokaverkko.firebaseio.com',
  projectId: 'ruokaverkko',
  storageBucket: '',
  messagingSenderId: '889714533669',
  appId: '1:889714533669:web:b3fafb25703d4e1f1f26cc'
}

firebase.initializeApp(firebaseConfig)

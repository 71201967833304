<template>
  <div class="captured-image-container">
    <img :src="imageUrl" alt="kuva" />

    <div class="captured-image-controls">
      <span @click="saveImg">save</span>
      <span @click="closeImg">close</span>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    imageUrl() {
      return this.$store.state.chart.screenCaptureUrl
    }
  },

  methods: {
    saveImg() {
      //console.log('hover image, right click + save as img');
      // here is the most important part because if you dont replace you will get a DOM 18 exception.
      const imitsi = this.imageUrl.replace("image/png", "image/octet-stream")
      window.location.href = imitsi
    },
    closeImg() {
      this.$store.dispatch('chart/SET_SCREEN_CAPTURE_URL', '')
    }
  }
}
</script>

<style lang="scss">
.captured-image-container {
  z-index: 100000000000000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 90%;
    max-height: 90%;
    height: auto;
  }
}

.captured-image-controls {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  color: white;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.4);
  z-index: 99999999999;
  font-size: 1.4rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 1rem;
  span {
    margin: 0 1.2rem;
    cursor: pointer;
  }
}
</style>

export default {
  // actionName: ({ commit, dispatch, state, rootState, getters }, payload) => {}

  SET_SCREEN_CAPTURE_URL: ({ commit }, imageUrl) => {
    commit('SET_SCREEN_CAPTURE_URL', imageUrl)
  },

  TOGGLE_ARROWHEADS: ({ commit }) => {
    commit('TOGGLE_ARROWHEADS')
  },

  TOGGLE_TRANSPARENCY: ({ commit }) => {
    commit('TOGGLE_TRANSPARENCY')
  }
}
